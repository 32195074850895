<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData" class="mb-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="">
					<a-card class="p-0 m-0 pb-15">
                        <a-row :gutter="24" class="px-20 pt-10">
                            <a-col :span="24" :md="10">
                                <div class="icon">
                                    <img id="classImg" width="100%" height="320px" :src="classDetails.thumbnail != null ? classDetails.thumbnail : 'https://media.istockphoto.com/id/590073366/photo/books-of-knowledge.jpg?s=612x612&w=0&k=20&c=h-uY-AOk62qTsKOxoa_vPp0BZPuYeDs_kV0bU4H0jaA='" alt="">
                                </div>
                            </a-col>
                            <a-col :span="24" :md="10">
                                <a-row :gutter="24">
                                    <a-col :span="24">
                                        <h4 class="mt-10 pt-10 pl-2">{{ classDetails.name }}</h4>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="24">
                                    <a-col :span="6" :sm="5" :md="3">
                                        <a-avatar :size="50" :src="classDetails.creator != null && classDetails.creator.profile ? classDetails.creator.profile : ``" />&nbsp;&nbsp;
                                    </a-col>
                                    <a-col :span="14" :sm="10" :md="13">
                                        <a-row :gutter="24" class="pt-5">
                                            <a-col :span="24">
                                                {{ classDetails.creator != null ? `${classDetails.creator.firstName} ${classDetails.creator.lastName}, Teacher` : 'Unknown user'}}
                                            </a-col>
                                            <a-col :span="24" class="pt-3">
                                                <svg v-for="(option, index) in 4" :key="index" class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                    <path d="M13.8 4.2a2 2 0 0 0-3.6 0L8.4 8.4l-4.6.3a2 2 0 0 0-1.1 3.5l3.5 3-1 4.4c-.5 1.7 1.4 3 2.9 2.1l3.9-2.3 3.9 2.3c1.5 1 3.4-.4 3-2.1l-1-4.4 3.4-3a2 2 0 0 0-1.1-3.5l-4.6-.3-1.8-4.2Z"/>
                                                </svg>
                                                <svg class="dark:text-white" style="color: #F9AA34; width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-width="2" d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"/>
                                                </svg>
                                            </a-col>
                                        </a-row> 
                                    </a-col>
                                    <a-col :span="24" :md="8" class="text-right">
                                        <a-button id="textBtn" type="text" @click="toTutorProfilePage(classDetails.creator.uuid)">View Profile</a-button>
                                    </a-col>
                                </a-row>
                                <a-row :gutter="24">
                                    <a-col :span="24" class="mt-0">
                                        <label style="font-size: 16px;" class="mt-10" v-if="classDetails.objective"><strong>Objective</strong></label>
                                        <p>{{ classDetails.objective }}</p>
                                    </a-col>
                                    <a-col :span="24">
                                            <svg class="text-primary dark:text-white"  style="width: 20px; position: absolute; top: 0px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 10h16m-8-3V4M7 7V4m10 3V4M5 20h14c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1H5a1 1 0 0 0-1 1v12c0 .6.4 1 1 1Zm3-7h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Zm-8 4h0v0h0v0Zm4 0h0v0h0v0Zm4 0h0v0h0v0Z"/>
                                            </svg>
                                            <span class="pb-15 pl-20 ml-5">
                                            Begins from <strong>{{ $Moment(classDetails.startTimestamp).format("DD MMM YYYY") }}</strong> to <strong>{{ $Moment(classDetails.startTimestamp).format("DD MMM YYYY") }}</strong>
                                        </span>
                                    </a-col>
                                    <a-col :span="24" class="mt-10">
                                            <svg class="text-primary dark:text-white" style="width: 20px; position: absolute; top: 0px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z" clip-rule="evenodd"/>
                                            </svg>
                                            <span class="pb-15 pl-20 ml-5">
                                            <strong>{{ classDetails.numOfSlots }}</strong> Slots remaining
                                        </span>
                                    </a-col>
                                    <a-col :span="24" class="mt-20">
                                        <!-- <a-button type="primary" :loading="joinClass.loading" @click="handleJoinClass">Join Class for {{ classDetails.price != 0 ? `${classDetails.price}/=` : 'FREE' }}</a-button> -->
                                    </a-col>

                                </a-row>
                            </a-col>
                            <a-col :span="24" :md="4">
                                <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20">

                                    <a-col :span="0" :md="24" :lg="24" class="text-center  justify-items-center"></a-col>
                                    <a-col :span="0" :md="24" :lg="24" class="text-center  justify-items-center"></a-col>
                                    <a-col :span="0" :md="24" :lg="24" class="text-center  justify-items-center"></a-col>
                                    <a-col :span="0" :md="24" :lg="24" class="text-center  justify-items-center"></a-col>
                                    <a-col :span="0" :md="24" :lg="24" class="text-center  justify-items-center"></a-col>
                                    <a-col :span="0" :md="24" :lg="24" class="text-center  justify-items-center"></a-col>

                                    <a-col :span="24" :md="24" :lg="24" class="text-center  justify-items-center">

                                        <h5 class="text-silabu pt-10">{{ classDetails.price != 0 ? `${classDetails.price}/=` : 'FREE' }}</h5>

                                    </a-col>

                                </a-row>
                            </a-col>
                        </a-row>
                    </a-card>
				</a-col>

                <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
                    <a-tabs v-model="activeTab" class="">
                        <a-tab-pane :key="1" tab="Topics">
                            <a-card class="pb-20">
                                Topics to be listed here
                            </a-card>
                        </a-tab-pane>
                        <a-tab-pane :key="2" tab="Features">
                            <a-card class="pb-20">
                                <a-row :gutter="24" class="px-20">
                                    <a-col :span="12" :md="6" v-for="(option, index) in classDetails.features" class="mt-10" :key="index">
                                        <span>{{ index + 1 }}. {{ formatFetures(option) }}</span>
                                        <span class="icon ml-5">
                                        <svg class="text-primary dark:text-white" style="width: 20px; position: absolute;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clip-rule="evenodd"/>
                                        </svg>
                                        </span>
                                    </a-col>
                                </a-row>
                            </a-card>
                        </a-tab-pane>
                        <a-tab-pane :key="3" tab="Assessment Modes">
                            <a-card class="pb-20">
                                <a-row :gutter="24" class="px-20">
                                    <a-col :span="12" :md="6" v-for="(option, index) in classDetails.assessmentModes" class="mt-10" :key="index">
                                        <span>{{ index + 1 }}. {{ formatFetures(option) }}</span>
                                        <span class="icon ml-5">
                                        <svg class="text-primary dark:text-white" style="width: 20px; position: absolute;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0Zm13.7-1.3a1 1 0 0 0-1.4-1.4L11 12.6l-1.8-1.8a1 1 0 0 0-1.4 1.4l2.5 2.5c.4.4 1 .4 1.4 0l4-4Z" clip-rule="evenodd"/>
                                        </svg>
                                        </span>
                                    </a-col>
                                </a-row>
                            </a-card>
                        </a-tab-pane>
                    </a-tabs>
                </a-col>

			</a-row>

            <a-row :gutter="24">
                <a-col :span="24">
                    
                </a-col>
            </a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;
    import { notification } from 'ant-design-vue';

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,
                activeTab: 1,
				classes: [],
                classDetails: {
                    uuid: null,
                    name: null,
                    objective: null,
                    isPublic: null,
                    educationSystems: [],
                    educationLevels: [],
                    educationGrades: [],
                    subjects: [],
                    numOfSlots: null,
                    rating: null,
                    creator: {},
                    setupCompleted: null,
                    isAcademic: null,
                    price: 0,
                    isForTeaching: null,
                    features: [],
                    assessmentModes: [],
                    startTimestamp: null,
                    endTimestamp: null,
                    thumbnail: null,
                    createdAt: null,
                    updatedAt: null,
                },

                joinClass: {
                    loading: false,
                },

				currentPage: 1,

      		}
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Notes', url: '/notes', isActive: false },
                { title: 'Details', url: '', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.getClassDetails()
		},
		methods: {

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            formatFetures(name) {
				let formattedName = name.replace(/_/g, ' ');
                let formattedName1 = formattedName.includes('mode') ? formattedName.substring(5) : formattedName;
                return formattedName1[0].toUpperCase() + formattedName1.substring(1);
            },

            toTutorProfilePage(uuid) {
                this.$router.push(`/tutors/view/${uuid}`)
            },

			async getClassDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/quizzes?uuid=${this.$route.params.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classDetails.uuid = response.data.data.uuid;
                        this.classDetails.name = response.data.data.name;
                        this.classDetails.objective = response.data.data.objective;
                        this.classDetails.isPublic = response.data.data.isPublic;
                        this.classDetails.educationSystems = response.data.data.educationSystems;
                        this.classDetails.educationLevels = response.data.data.educationLevels;
                        this.classDetails.educationGrades = response.data.data.educationGrades;
                        this.classDetails.subjects = response.data.data.subjects;
                        this.classDetails.numOfSlots = response.data.data.numOfSlots;
                        this.classDetails.rating = response.data.data.rating;
                        this.classDetails.creator = response.data.data.creator;
                        this.classDetails.setupCompleted = response.data.data.setupCompleted;
                        this.classDetails.isAcademic = response.data.data.isAcademic;
                        this.classDetails.price = response.data.data.price;
                        this.classDetails.isForTeaching = response.data.data.isForTeaching;
                        this.classDetails.features = response.data.data.features;
                        this.classDetails.assessmentModes = response.data.data.assessmentModes;
                        this.classDetails.startTimestamp = response.data.data.startTimestamp;
                        this.classDetails.endTimestamp = response.data.data.endTimestamp;
                        this.classDetails.thumbnail = response.data.data.thumbnail;
                        this.classDetails.createdAt = response.data.data.createdAt;
                        this.classDetails.updatedAt = response.data.data.updatedAt;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async handleJoinClass() {

                if(this.classDetails.price > 0) {
                    this.notify("Payment module is yet to be implemented (For paid classes)", 'error');
                }else {

                    const userInfo = await localStorage.getItem('user_details')

					let userDetails = JSON.parse(userInfo);

				    this.joinClass.loading = true;

				    let url = `${this.$BACKEND_URL}/members/${this.$route.params.uuid}`;

                    this.$AXIOS.post(url, {uuid: userDetails.uuid}).then(async(response) => {

                        this.notify('You have successfully joined the class', 'success')

                        this.joinClass.loading = false;

                        this.$router.push(`/my-classes/view/${this.$route.params.uuid}`)

                    }).catch(async(error) => {
                        
                        this.joinClass.loading = false;

                        this.newClass.errorMessage = error.response != null  && error.response.data != null ? error.response.data.message : "Connection error";
                        this.newClass.errorShow = true

                        if(error.response && error.response.status == 401) {
                            await localStorage.setItem("user_token", null);
                            await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                            this.$router.push(`/sign-in`);
                        }
                    });
                }

            },

            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	/* border-radius: 10px; */
}

a:hover {
    color: inherit !important;
}
</style>
